<template>
    <section id="dashboard-ecommerce">
        <b-row class="match-height">
            <b-col cols="12">
                <b-card no-body class="card-statistics">
                    <b-card-header>
                        <b-card-title>Bienvenido a Steknos</b-card-title>
                    </b-card-header>
                </b-card>

                <b-card v-if="userData.roles.find(role => role.name === 'Gmao Cliente')">
                    Escanea un codigo QR para poder ver la ficha de un equipo.
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle } from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
    },
    data() {
        return {
            userData: this.$store.state.AppActiveUser,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
